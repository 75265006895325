<template>
    <el-main>
        <el-button type="primary" size="small" class="add_goods" @click="$router.push(`/catering/addGoods`)">
            + 添加商品
        </el-button>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="菜品名称：">
                <el-input v-model="dataForm.cuisine_name" size="small" placeholder="请输入菜品名称"></el-input>
            </el-form-item>
            <el-form-item label="是否上架：">
                <el-select v-model="dataForm.status" size="small" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="上架" :value="1"></el-option>
                    <el-option label="下架" :value="-1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="菜品分类：">
                <el-select v-model="dataForm.classify_id" size="small" filterable clearable>
                    <el-option v-for="item in goodsClass" :key="item.id" :label="item.classify_name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="get_data_list(1)">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="data_list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id" :key="key">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="title" label="排序" align="center">
                <template #header>
                    <div class="edit">
                        <span>排序</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                s
                <template slot-scope="scope">
                    <el-input type="number" :min="0" size="small" v-model="scope.row.sort"
                        @change="editInfo(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="菜品主图" align="center">
                <template v-slot="{ row }">
                    <el-image style="width: 50px; height: 50px" :src="row.cuisine_picture"
                        :preview-src-list="[row.cuisine_picture]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="classify_name" label="菜品分类" align="center"></el-table-column>
            <el-table-column prop="cuisine_name" label="菜品名称" align="center"></el-table-column>
            <el-table-column prop="title" label="菜品价格" align="center">
                <template #header>
                    <div class="edit">
                        <span>菜品价格</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input type="number" :min="0" size="small" v-model="scope.row.price"
                        @change="editInfo(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="上/下架" align="center">
                <template v-slot="{ row }">
                    <el-button size="mini" :type="row.status == -1 ? 'primary' : 'danger'" @click="statusChange(row)">{{
                        row.status == -1 ? '上架' : '下架' }}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="editGoods(row)">编辑</el-button>
                    <el-button type="text" @click="deleteGoods(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="dataForm.page" :pageNum="dataForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>
  
<script>
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
    components: {
        Paging,
    },
    created () {
        this.get_data_list();
        this.getGoodsClassifyLists()
    },
    data () {
        return {
            getDateformat,
            // 列表查询条件
            dataForm: {
                page: 1,
                rows: 10,
                status: "",
                cuisine_name: '',
                classify_id: ''
            },

            data_list: [],
            company_list: [],
            total_number: 0,
            key: new Date(),
            goodsClass: []
        };
    },
    methods: {
        getGoodsClassifyLists () {
            this.$axios.post(this.$api.catering.GoodsClassifyLists).then(res => {
                if (res.code == 0) {
                    this.goodsClass = res.result
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        updateData (val, status) {
            if (status == 0) {
                this.dataForm.rows = val;
            } else {
                this.dataForm.page = val;
            }
            this.get_data_list();
        },
        clearSearch () {
            this.dataForm = {
                page: 1,
                rows: 10,
                status: "",
                cuisine_name: ''
            }
            this.get_data_list(1);
        },
        get_data_list (style) {
            if (style) this.dataForm.page = 1;
            this.$axios.post(this.$api.catering.GoodsList, this.dataForm).then(res => {
                if (res.code === 0) {
                    this.data_list = res.result.list;
                    this.total_number = res.result.total_number;
                    this.key = new Date()
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        editInfo (row) {
            this.$axios.post(this.$api.catering.GoodsEditInfo, {
                id: row.id,
                price: row.price,
                status: row.status,
                sort: row.sort
            })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success(`编辑成功`);
                        this.get_data_list();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        // 上下架商品
        statusChange (data) {
            this.$confirm(`此操作将${data.status == 1 ? '下架' : '上架'}该商品, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.catering.GoodsEditInfo, {
                        id: data.id,
                        status: data.status == 1 ? -1 : 1,
                        price: data.price,
                        sort: data.sort
                    })
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success(`${data.status == 1 ? '下架' : '上架'}成功`);
                                this.get_data_list();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                        .catch(() => { });
                })
        },
        // 点击删除商品
        deleteGoods (data) {
            this.$confirm('此操作将删除该商品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.catering.GoodsDel, {
                        id: data.id,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success(`删除成功`);
                        this.get_data_list();
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        // 编辑商品
        editGoods (data) {
            this.$router.push(`/catering/editGoods?g_id=${data.id}`);
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;

    .add_goods {
        margin: 16px 0;
    }
}
</style>